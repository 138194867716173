import "./assets/styles/main.scss";
require('typeface-raleway');

// Intersect polyfill
import 'intersection-observer';

// Picture tag polyfill
import 'picturefill';

// Object fit polyfill
import objectFitPolyfill from "object-fit-images";

// Import jQuery for checkout pages (sorry)
import $ from 'jquery';
window.$ = $;

import Vue from "vue";
import CarrouselSteps from "./components/CarrouselSteps";
import CarrouselOurProduction from "./components/CarrouselOurProduction";
import ProductionListOverview from "./components/ProductionListOverview";
import HeroHome from "./components/HeroHome";
import HeaderNavbar from "./components/HeaderNavbar";
import ScrollUpMenu from "./components/ScrollUpMenu";
import SolutionsTabBar from "./components/SolutionsTabBar";
import ModalOpener from "./components/ModalOpener";
import CarrouselFullWidth from "./components/CarrouselFullWidth";
import FeaturedOptionsAndAccessories from "./components/FeaturedOptionsAndAccessories";
import CarrouselProductPhotos from "./components/CarrouselProductPhotos";
import AddToCart from "./components/AddToCart";
import CsrfInput from "./components/CsrfInput";
import CustomerNav from "./components/CustomerNav";
import ProductListFilterSummary from "./components/ProductListFilterSummary";
import ProductList from "./components/ProductList";
import ProductCard from "./components/ProductCard";

new Vue({
    el: "#app",

    components: {
        CarrouselSteps,
        CarrouselOurProduction,
        ProductionListOverview,
        HeroHome,
        HeaderNavbar,
        ScrollUpMenu,
        SolutionsTabBar,
        ModalOpener,
        CarrouselFullWidth,
        FeaturedOptionsAndAccessories,
        CarrouselProductPhotos,
        AddToCart,
        CsrfInput,
        CustomerNav,
        ProductListFilterSummary,
        ProductList,
        ProductCard,
    },

    mounted() {
        objectFitPolyfill();
    }
});
