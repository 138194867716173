<template>
    <div class="carrousel-steps my-10 md:mb-16">
        <div class="container mx-auto">
            <h2 class="overline text-center md:mb-7">
                {{ heading }}
            </h2>
            <div class="flex md:hidden justify-center mb-1">
                <dots :current-step="currentStepId" :length="steps.length" @updateSlide="updateSlide"></dots>
            </div>
            <div class="carrousel-steps__step-container container mx-auto flex flex-col items-center justify-center">
                <carrousel-transition>
                    <div
                        :key="currentStepId"
                        :class="{'md:flex-row': componentImagePosition === 'left', 'md:flex-row-reverse': componentImagePosition === 'right'}"
                        class="w-full flex flex-col"
                    >
                        <div class="w-full md:w-1/2">
                            <div
                                :class="{'carrousel-image-container--left': componentImagePosition === 'left', 'carrousel-image-container--right': componentImagePosition === 'right'}"
                                class="carrousel-image-container carrousel-steps__image-container relative overflow-hidden"
                            >
                                <img :src="currentStep.image.url" :alt="currentStep.image.title" class="w-full absolute inset-0">
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 px-container md:mx-0 md:px-0 md:pt-4">
                            <div class="carrousel-pagination-container hidden md:flex items-center justify-center mb-3">
                                <timer :duration="3.5" class="mr-2 lg:mr-5 xl:mr-10" @timelineFilled="next"></timer>

                                <button class="px-1" @click="previous">
                                    <img src="@/static/images/icons/arrow-left.svg" alt="Previous">
                                </button>
                                <button class="px-1" @click="next">
                                    <img src="@/static/images/icons/arrow-right.svg" alt="Next">
                                </button>
                            </div>
                            <div class="carrousel-text-container border border-black flex flex-col items-center py-5 px-4 md:py-16">
                                <div>
                                    <h3 class="h1">
                                        {{ currentStep.heading }}
                                    </h3>
                                    <div class="mt-1 md:mt-2" v-html="currentStep.text"></div>
                                </div>
                            </div>
                            <div
                                :class="{'md:text-left carrousel-button--left': componentImagePosition === 'left', 'md:text-right carrousel-button--right': componentImagePosition === 'right'}"
                                class="carrousel-button relative overflow-hidden text-center mt-3 md:mt-0"
                            >
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </carrousel-transition>
            </div>
        </div>
    </div>
</template>

<script>
    import CarrouselTransition from "../transitions/CarrouselTransition";
    import Timer from "./Timer";
    import Dots from "./Dots";

    export default {
        name: "CarrouselSteps",

        components: {
            CarrouselTransition,
            Timer,
            Dots,
        },

        props: {
            heading: {
                type: String,
                default: '',
            },
            steps: {
                type: Array,
                default: null,
            },
        },

        data() {
            return {
                currentStepId: 0,
                nextStepId: 0,
                timeline: null,
            }
        },

        computed: {
            currentStep() {
                return this.steps[this.currentStepId];
            },

            componentImagePosition() {
                if (this.currentStepId % 2 === 0) {
                    return 'left';
                }

                return 'right';
            }
        },

        methods: {
            next() {
                if (this.currentStepId === this.steps.length -1) {
                    this.currentStepId = 0;
                    return;
                }

                this.currentStepId++;
            },

            previous() {
                if (this.currentStepId === 0) {
                    this.currentStepId = this.steps.length -1;
                    return;
                }

                this.currentStepId--;
            },

            updateSlide(key) {
                this.currentStepId = key;
            }
        }
    }
</script>

<style scoped lang="scss">
    .carrousel-steps {
        &__image-container {
            padding-top: 85.83%;
        }
    }
</style>
