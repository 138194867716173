<template>
    <div :class="headerClass" class="header-navbar fixed top-0 left-0 right-0 z-20 grid-container flex items-center justify-between h-full">
        <div class="relative z-1">
            <slot name="logo"></slot>
        </div>

        <button :class="{'header-navbar__menu-button--opened': isMenuOpened}" class="relative z-1 xl:hidden header-navbar__menu-button navigation flex items-center uppercase text-white" @click="toggleMenu">
            {{ menuLabel }}
        </button>

        <div
            :class="{
                'hidden xl:flex': !isMenuOpened,
                'mx-auto': featuredMenuItems.length < 1
            }"
            class="header-navbar__menu absolute xl:relative flex flex-col items-center justify-around xl:justify-start xl:flex-row w-full xl:w-auto px-2 md:px-0"
        >
            <template v-for="(menuItem, key) in menuItems">
                <a
                    ref="menuItem"
                    :key="key"
                    :href="menuItem.url"
                    :target="menuItem.target"
                    class="navigation uppercase text-white mx-3"
                    :class="{ 'text-gray-500': menuItem.isCurrent }"
                >
                    {{ menuItem.title }}
                </a>
            </template>

            <div v-if="featuredMenuItems.length" class="flex flex-col md:flex-row w-full md:w-auto mx-2 md:mx-0 xl:ml-3 border border-gray-300">
                <template v-for="(menuItem, key) in featuredMenuItems">
                    <a
                        :key="`featured_${key}`"
                        :href="menuItem.url"
                        :target="menuItem.target"
                        :class="{'border-b md:border-b-0 md:border-r border-gray-300': key !== featuredMenuItems.length-1}"
                        class="flex justify-center items-center link-s text-white px-2 h-8 xl:h-5"
                    >
                        <img class="w-2 h-2" :src="menuItem.picto.url" :alt="menuItem.picto.title">
                        <span :class="{ 'xl:hidden' : menuItem.hideTextOnDesktop }" class="header-navbar__featured-item-text">{{ menuItem.title }}</span>
                        <span v-if="menuItem.showCartProductCount" class="header-navbar__featured-item-text caption header-navbar__featured-item-text--count">({{ cartProductCount }})</span>
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import gsap from "gsap";
    import {CSSPlugin} from "gsap/CSSPlugin";
    import {Power4} from "gsap/all";

    export default {
        name: "HeaderNavbar",

        props: {
            isTransparent:     { type: Boolean, default: false },
            isAnimated:        { type: Boolean, default: false },
            menuItems:         { type: Array,   required: true },
            menuLabel:         { type: String,  required: true },
            featuredMenuItems: { type: Array,   required: true },
            cartProductCount:  { type: Number,  required: true }
        },

        data() {
            return {
                isSticky: false,
                isMenuOpened: false,
                menuTimeline: null,
            }
        },

        computed: {
            headerClass() {
                if(this.isAnimated) {
                    return this.getHeaderStyle() + ' invisible';
                }

                return this.getHeaderStyle();
            },
        },

        mounted() {
            gsap.registerPlugin(CSSPlugin);
            this.prepareMenuTimeline();

            const body = document.querySelector('body');

            if(!this.isTransparent) {
                body.style.paddingTop = this.$el.offsetHeight + "px";
            }

            this.scrollHandler();
            window.addEventListener('scroll', this.scrollHandler);

            if(this.isAnimated) {
                setTimeout(() => {
                    this.displayHeader();
                }, 5000);
            }
            else {
                this.menuTimeline.progress(1);
            }
        },

        destroyed() {
            window.removeEventListener('scroll', this.scrollHandler);
        },

        methods: {
            getHeaderStyle() {
                if (this.isTransparent && !this.isSticky) {
                    return 'bg-transparent';
                }

                if(this.isSticky) {
                    return 'header-navbar--sticky bg-black'
                }

                return 'bg-black';
            },

            scrollHandler() {
                if(window.pageYOffset > this.$el.offsetHeight && !this.isSticky) {
                    this.isSticky = true;
                    return;
                }

                if(window.pageYOffset < this.$el.offsetHeight && this.isSticky) {
                    this.isSticky = false;
                }
            },

            displayHeader() {
                gsap.to(this.$el, {
                    autoAlpha: 1,
                    duration: 0.8,
                    ease: Power4.easeInOut,
                });

                this.menuTimeline.play(0);
            },

            prepareMenuTimeline() {
                this.menuTimeline = gsap.timeline({paused: true});

                this.$refs.menuItem.forEach((element, index) => {
                    this.menuTimeline.from(element, {
                        y: -30,
                        autoAlpha: 0,
                        duration: 1.25,
                        ease: Power4.easeInOut
                    }, 0.1 * index);
                });
            },

            toggleMenu() {
                if(this.isMenuOpened === false){
                    this.isMenuOpened = true;
                    this.menuTimeline.timeScale(2).play(0);
                    return;
                }

                this.isMenuOpened = false
            }
        }
    }
</script>

<style lang="scss">
    .header-navbar {
        height: 5.25rem;

        transition: background-color 200ms ease-in-out, height 200ms ease-in-out;

        @screen lg {
            height: 6rem;
        }

        &--sticky {
            height: 4rem;

            .header-navbar__menu {
                padding-top: 4rem;

                @screen lg {
                    @apply pt-0;
                }
            }
        }

        &__menu {
            @apply bg-black left-0 top-0 h-screen;
            padding-top: 5.25rem;

            @screen xl {
                @apply h-auto left-auto top-auto pt-0 bg-transparent;
            }
        }

        &__menu-button {
            &::after {
                @apply w-2 h-2 ml-1 bg-cover;
                content: '';
                background-image: url('~@/static/images/icons/burger.svg');
            }

            &--opened {
                &::after {
                    background-image: url('~@/static/images/icons/cross-white.svg');
                }
            }
        }

        &__featured-item-text {
            margin-left: 0.25rem;

            &--count {
                @apply text-gray-300;
            }
        }
    }
</style>
