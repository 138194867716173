<template>
    <form method="post" class="grid-col w-full md:w-7/12 flex flex-col pb-4">
        <input type="hidden" :name="csrfToken.name" :value="csrfToken.value" />
        <slot name="hidden-inputs"></slot>
        <slot v-if="redirectToCart" name="redirect-cart-input"></slot>
        <slot v-if="!redirectToCart" name="continue-shopping-input"></slot>

        <h5 v-if="hasVariants" class="mb-2">
            <span class="font-light">
                {{ variantsTitle }}
            </span>
        </h5>

        <div v-if="finishes.length > 0" class="variant-select">
            <span class="variant-select__label">
                {{ finishTitle }}
                <div class="variant-name text-xs">
                    {{ currentVariant ? currentVariant.finish.title : null }}
                </div>
            </span>
            <span class="variant-select__radio">
                <span v-for="(item, key) in finishes" :key="key" class="variant-select__radio-input-container">
                    <input
                        :id="'finish-'+item.id"
                        v-model="currentFinish"
                        :value="item"
                        class="variant-select__radio-input"
                        type="radio"
                        name="variant-finish"
                        :title="item.title"
                    >
                    <label :for="'finish-'+item.id" class="variant-select__radio-label cursor-pointer">
                        <span class="variant-select__radio-dot" :style="`background-image: url('${item.textureUrl}')`"></span>
                    </label>
                </span>
            </span>
        </div>

        <label v-if="hoodTypes.length > 0" class="variant-select">
            <span class="variant-select__label">
                {{ hoodTypeTitle }}
            </span>
            <select v-model="currentHoodType" class="variant-select__select">
                <option v-for="(item, key) in hoodTypes" :key="key" :value="item">
                    {{ item.label }}
                </option>
            </select>
        </label>

        <variant-count-select
            v-if="pairOfShoesCounts.length > 0"
            v-model="currentPairOfShoesCount"
            :counts="pairOfShoesCounts"
            :label="shoesTitle"
        ></variant-count-select>

        <variant-count-select
            v-if="pairOfGlovesCounts.length > 0"
            v-model="currentPairOfGlovesCount"
            :counts="pairOfGlovesCounts"
            :label="glovesTitle"
        ></variant-count-select>

        <variant-count-select
            v-if="pairOfSkisCounts.length > 0"
            v-model="currentPairOfSkisCount"
            :counts="pairOfSkisCounts"
            :label="skisTitle"
        ></variant-count-select>

        <variant-count-select
            v-if="snowboardCounts.length > 0"
            v-model="currentSnowboardCount"
            :counts="snowboardCounts"
            :label="snowboardsTitle"
        ></variant-count-select>

        <h5 v-if="additionalProducts.length > 0" class="mt-4 mb-2">
            <span class="font-light">
                {{ additionalProductsTitle }}
            </span>
        </h5>

        <template v-for="(additionalProduct, key) in additionalProducts">
            <add-additional-product
                :key="key"
                :additional-product="additionalProduct"
                @addAdditionalProduct="addAdditionalProduct(additionalProduct)"
                @removeAdditionalProduct="removeAdditionalProduct(additionalProduct)"
            ></add-additional-product>
        </template>

        <template v-if="currentVariant">
            <template v-for="(purchasable, key) in purchasables">
                <input
                    :key="`option-${key}`"
                    :value="purchasable.id"
                    type="hidden"
                    :name="`purchasables[${key}][id]`"
                >
            </template>
        </template>

        <div v-if="currentVariant" class="flex flex-wrap items-end mt-4 md:mt-6">
            <h2 class="h2 mr-3">
                <span class="font-light">
                    {{ formatPrice(purchasablesPrice) }} €
                    <sup class="h5">
                        <span class="font-light">
                            {{ taxesTitle }}
                        </span>
                    </sup>
                </span>
            </h2>

            <del v-if="currentVariant.crossedPrice" class="text-gray-500 mr-4">
                {{ formatPrice(purchasablesCrossedPrice) }} €
            </del>

            <p v-if="hasVariants" class="caption">
                {{ totalPriceTitle }}
            </p>
            <p v-if="!hasVariants" class="caption">
                {{ totalPriceTitleNoVariant }}
            </p>
        </div>

        <div class="flex flex-col items-start md:items-center md:flex-row mt-3 md:mt-4">
            <button class="btn btn--positive btn--l" type="submit" :disabled="!currentVariant">
                {{ submitTitle }}
            </button>

            <button class="btn-text btn-text--line btn-text--positive mt-2 md:mt-0 md:ml-3" :disabled="!currentVariant" @click.prevent="validateAndContinueShopping()">
                {{ addToCartAndContinueShoppingTitle }}
            </button>
        </div>
    </form>
</template>

<script>
    import VariantCountSelect from "./VariantCountSelect";
    import AddAdditionalProduct from "./AddAdditionalProduct";
    import _ from "lodash";
    import Vue from "vue";

    export default {
        name: "AddToCart",

        components: {
            VariantCountSelect,
            AddAdditionalProduct,
        },

        props: {
            variants: {
                type: Array,
                required: true,
            },
            variantsTitle: {
                type: String,
                default: '',
            },
            finishTitle: {
                type: String,
                default: '',
            },
            hoodTypeTitle: {
                type: String,
                default: '',
            },
            shoesTitle: {
                type: String,
                default: '',
            },
            glovesTitle: {
                type: String,
                default: '',
            },
            skisTitle: {
                type: String,
                default: '',
            },
            snowboardsTitle: {
                type: String,
                default: '',
            },
            submitTitle: {
                type: String,
                default: '',
            },
            totalPriceTitle: {
                type: String,
                default: '',
            },
            totalPriceTitleNoVariant: {
                type: String,
                default: '',
            },
            taxesTitle: {
                type: String,
                default: '',
            },
            additionalProducts: {
                type: Array,
                default: null,
            },
            additionalProductsTitle: {
                type: String,
                default: '',
            },
            addToCartAndContinueShoppingTitle: {
                type: String,
                default: '',
            },
            hasVariants: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                currentFinish: null,
                currentHoodType: null,
                currentPairOfShoesCount: null,
                currentPairOfGlovesCount: null,
                currentPairOfSkisCount: null,
                currentSnowboardCount: null,
                selectedAdditionalProducts: [],
                redirectToCart: true,
                csrfToken: {
                    name:  window.Craft.csrfTokenName,
                    value: window.Craft.csrfTokenValue,
                }
            }
        },

        computed: {
            finishes() {
                const finishes = this.variants.reduce((acc, val) => val.finish === null ? acc : acc.concat(val.finish), []);
                return _.uniqBy(finishes, 'id');
            },

            hoodTypes() {
                const hoodTypes = this.variants.reduce((acc, val) => val.hoodType === null ? acc : acc.concat(val.hoodType), []);
                return _.uniqBy(hoodTypes, 'value');
            },

            pairOfShoesCounts() {
                const pairOfShoesCounts = this.variants.reduce((acc, val) => val.pairOfShoesCount === null ? acc : acc.concat(val.pairOfShoesCount), []);
                return _.uniq(pairOfShoesCounts);
            },

            pairOfGlovesCounts() {
                const pairOfGlovesCounts = this.variants.reduce((acc, val) => val.pairOfGlovesCount === null ? acc : acc.concat(val.pairOfGlovesCount), []);
                return _.uniq(pairOfGlovesCounts);
            },

            pairOfSkisCounts() {
                const pairOfSkisCounts = this.variants.reduce((acc, val) => val.pairOfSkisCount === null ? acc : acc.concat(val.pairOfSkisCount), []);
                return _.uniq(pairOfSkisCounts);
            },

            snowboardCounts() {
                const snowboardCounts = this.variants.reduce((acc, val) => val.snowboardCount === null ? acc : acc.concat(val.snowboardCount), []);
                return _.uniq(snowboardCounts);
            },

            currentVariant() {
                for (const i in this.variants) {
                    if (
                        JSON.stringify(this.variants[i].finish) === JSON.stringify(this.currentFinish)
                        && JSON.stringify(this.variants[i].hoodType) === JSON.stringify(this.currentHoodType)
                        && this.variants[i].pairOfShoesCount === this.currentPairOfShoesCount
                        && this.variants[i].pairOfGlovesCount === this.currentPairOfGlovesCount
                        && this.variants[i].pairOfSkisCount === this.currentPairOfSkisCount
                        && this.variants[i].snowboardCount === this.currentSnowboardCount
                    ) {
                        return this.variants[i];
                    }
                }

                return null;
            },

            purchasables() {
                if (this.selectedAdditionalProducts.length === 0) {
                    return [this.currentVariant];
                }

                return [this.currentVariant].concat(this.selectedAdditionalProducts);
            },

            purchasablesPrice() {
                let price = 0;

                for(const i in this.purchasables) {
                    price += parseFloat(this.purchasables[i].price);
                }

                return price;
            },

            purchasablesCrossedPrice() {
                let price = 0;

                for(const i in this.purchasables) {
                    this.purchasables[i].crossedPrice ?
                        price += parseFloat(this.purchasables[i].crossedPrice) :
                        price += parseFloat(this.purchasables[i].price);
                }

                return price;
            },
        },

        mounted() {
            this.setDefaultVariant();
        },

        methods: {
            setDefaultVariant() {
                for (const i in this.variants) {
                    if(this.variants[i].isDefault) {
                        this.currentFinish = this.variants[i].finish;
                        this.currentHoodType = this.variants[i].hoodType;
                        this.currentPairOfShoesCount = this.variants[i].pairOfShoesCount;
                        this.currentPairOfGlovesCount = this.variants[i].pairOfGlovesCount;
                        this.currentPairOfSkisCount = this.variants[i].pairOfSkisCount;
                        this.currentSnowboardCount = this.variants[i].snowboardCount;
                        return;
                    }
                }
            },

            formatPrice(price) {
                return price.toFixed(2);
            },

            addAdditionalProduct(addtionalProduct) {
                this.selectedAdditionalProducts.push(addtionalProduct);
            },

            removeAdditionalProduct(addtionalProduct) {
                this.selectedAdditionalProducts = this.selectedAdditionalProducts.filter((item) => {
                    return item.id !== addtionalProduct.id;
                });
            },

            validateAndContinueShopping() {
                this.redirectToCart = false;
                Vue.nextTick().then(() => {
                    this.$el.submit();
                })
            }
        }
    }
</script>
