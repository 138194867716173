<template>
    <section class="product-list">
        <div v-if="products.length === 0" class="grid-container container my-3">
            {{ noProductText }}
        </div>

        <div v-else class="grid-container">
            <div class="grid-row flex-wrap">
                <div class="grid-col md:overflow-auto w-full md:w-1/3 xl:w-1/4 my-3" :style="`max-height: ${filtersMaxHeight};`">
                    <div class="flex justify-between items-center bg-black xl:mx-2 px-2 py-1">
                        <button class="flex items-center focus:outline-none" @click="isMobileOpened = !isMobileOpened">
                            <img src="@/static/images/icons/filters-white.svg" alt="Filters logo" class="mr-1">
                            <span class="product-list__filter-button font-bold text-white text-sm">
                                {{ filtersButtonText }}
                                <span v-if="selectedFilters.length > 0">({{ selectedFilters.length }})</span>
                            </span>

                            <img
                                src="@/static/images/icons/chevron-down-white.svg"
                                alt="Filters logo"
                                :class="{'product-list__button-chevron--opened': isMobileOpened}"
                                class="product-list__button-chevron md:hidden ml-1"
                            >
                        </button>

                        <button class="link-s text-white focus:outline-none" @click="resetSelectedFilters">
                            {{ resetButtonText }}
                        </button>
                    </div>

                    <template v-for="(filter, key) in availableFilters">
                        <div
                            v-if="filter.children.length > 0"
                            ref="filters"
                            :key="key"
                            :class="{
                                'block': isMobileOpened,
                                'hidden md:block': !isMobileOpened,
                            }"
                            class="xl:px-2 my-3"
                        >
                            <accordion>
                                <template #button-text>
                                    <h5 class="text-left">
                                        <span class="font-light">{{ filter.title }}</span>
                                    </h5>
                                </template>

                                <template #content>
                                    <div class="flex flex-col">
                                        <div v-for="(child, childKey) in filter.children" :key="`${key}-${childKey}`" class="border-b border-black py-1 mt-1">
                                            <div class="checkbox pl-2">
                                                <input
                                                    :id="`filter-${key}-${childKey}`"
                                                    v-model="selectedFilters"
                                                    class="checkbox__input"
                                                    type="checkbox"
                                                    name="product-filter"
                                                    :value="child.id"
                                                >
                                                <label class="checkbox__label body-4" :for="`filter-${key}-${childKey}`">
                                                    <img
                                                        v-if="child.picto"
                                                        :src="child.picto.url"
                                                        :alt="child.picto.alt"
                                                        class="checkbox__label-picto mx-1"
                                                    >
                                                    {{ child.title }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </accordion>
                        </div>
                    </template>
                </div>

                <div class="grid-col w-full md:w-2/3 xl:w-3/4">
                    <div ref="products" class="grid-row flex-wrap">
                        <div v-for="(product, key) in filteredProducts" :key="key" class="grid-col w-full md:w-1/2 xl:w-1/3 my-2 md:my-3">
                            <product-card v-if="product.type === 'product-card'" :product="product" :price-starting-at-text="priceStartingAtText"></product-card>
                            <product-cta-card v-if="product.type === 'cta-card'" :card="product"></product-cta-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ProductCard from "./ProductCard";
    import ProductCtaCard from "./ProductCtaCard";
    import _ from 'lodash';
    import Accordion from "./Accordion";

    export default {
        name: "ProductList",

        components: {
            ProductCard,
            ProductCtaCard,
            Accordion,
        },

        props: {
            products: {
                type: Array,
                required: true,
            },
            filters: {
                type: Array,
                required: true,
            },
            noProductText: {
                type: String,
                default: '',
            },
            priceStartingAtText: {
                type: String,
                default: '',
            },
            filtersButtonText: {
                type: String,
                default: '',
            },
            resetButtonText: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                selectedFilters: [],
                filteredProducts: [],
                availableFilters: [],
                isMobileOpened: false,
                filtersMaxHeight: 'none',
            }
        },

        watch: {
            selectedFilters() {
                this.filterProducts();
                this.getAvailableFilters();
            },
        },

        mounted() {
            this.filterProducts();
            this.getAvailableFilters();

            window.addEventListener('resize', this.setFiltersContainerMaxHeight);
        },

        destroyed() {
            window.removeEventListener('resize', this.setFiltersContainerMaxHeight);
        },

        methods: {
            filterProducts() {
                this.filteredProducts = this.products;

                for (let i in this.selectedFilters) {
                    this.filteredProducts = this.filteredProducts.filter(product => {
                        if (product.type === 'cta-card') {
                            return this.selectedFilters === [];
                        }

                        return product.filters.includes(this.selectedFilters[i]);
                    });
                }

                this.$nextTick(() => {
                    setTimeout(() => {
                        this.setFiltersContainerMaxHeight();
                    }, 500);
                });
            },

            getAvailableFilters() {
                this.availableFilters = _.cloneDeep(this.filters);

                for (let i in this.availableFilters) {
                    this.availableFilters[i].children = this.availableFilters[i].children.filter(filter => {
                        let isFilterAvailable = false;

                        for (let j in this.filteredProducts) {
                            if (this.filteredProducts[j].filters.includes(filter.id)) {
                                isFilterAvailable = true;
                                break;
                            }
                        }

                        return isFilterAvailable;
                    });
                }
            },

            resetSelectedFilters() {
                this.selectedFilters = [];
            },

            setFiltersContainerMaxHeight() {
                if (window.innerWidth < 768) {
                    this.filtersMaxHeight = 'none';
                    return;
                }

                this.filtersMaxHeight = this.$refs.products.offsetHeight + 'px';
            }
        },
    }
</script>

<style scoped lang="scss">
    .product-list {
        &__filter-button {
            letter-spacing: (0.7em/14);
        }

        &__button-chevron {
            transition: transform 150ms;

            &--opened {
                transform: rotate(180deg);
            }
        }
    }
</style>
