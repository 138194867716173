<template>
    <div class="flex flex-col md:flex-row md:items-center md:justify-between mb-2 px-2 pb-1 md:pb-0 border-b border-black">
        <div :class="labelClass">
            {{ additionalProduct.title }}
        </div>
        <div class="flex justify-between">
            <span class="h4">
                <span class="font-light">
                    {{ additionalProduct.price }} €
                </span>
            </span>
            <button class="ml-2" @click.prevent="toggleAdditionalProduct">
                <span v-if="!isActive">
                    <img class="w-2 h-2" src="@/static/images/icons/add.svg" alt="+">
                </span>
                <span v-if="isActive">
                    <img class="w-2 h-2" src="@/static/images/icons/remove.svg" alt="-">
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddAdditionalProduct",

        props: {
            additionalProduct: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                isActive: false,
            }
        },

        computed: {
            labelClass() {
                return this.isActive ? 'body-3' : 'body-4';
            }
        },

        methods: {
            toggleAdditionalProduct() {
                if(this.isActive) {
                    this.$emit('removeAdditionalProduct');
                    this.isActive = false;
                    return;
                }

                this.$emit('addAdditionalProduct');
                this.isActive = true;
            }
        }
    }
</script>

<style scoped>

</style>
