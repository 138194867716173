<template>
    <div class="timer relative w-full h-px bg-gray-200">
        <div ref="progressBar" class="timer__progress-bar absolute bottom-0 left-0 bg-black"></div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Linear} from "gsap/all"

    export default {
        name: "Timer",

        props: {
            duration: {
                type: Number,
                default: 3,
            }
        },

        data() {
            return {
                timeline: null,
                interval: null,
            }
        },

        mounted() {
            this.prepareTimeline();

            this.timeline.play();

            //this.interval = setInterval(this.restartTimeline, this.duration * 1000);
        },

        destroyed() {
            clearInterval(this.interval);
        },

        methods: {
            prepareTimeline() {
                this.timeline = gsap.timeline({paused: true});

                this.timeline.set(this.$refs.progressBar, {
                    width: 0,
                });

                this.timeline.to(this.$refs.progressBar, {
                    width: '100%',
                    ease: Linear.easeNone,
                    duration: this.duration,
                });

                this.timeline.add(() => {
                    this.$emit('timelineFilled');
                })
            },

            restartTimeline() {
                this.timeline.play(0);
                this.$emit('timelineFilled');
            }
        }
    }
</script>

<style scoped lang="scss">
    .timer {
        max-width: 16.25rem;

        &__progress-bar {
            width: 0;
            height: 0.125rem;
        }
    }
</style>
