<template>
    <transition name="modal-fade">
        <div v-if="isOpened" class="modal fixed z-30 flex justify-center items-center inset-0 w-screen h-screen" @click="$emit('close')">
            <div class="modal__container relative w-full h-full max-h-screen md:h-auto bg-white overflow-y-auto py-2 md:py-6 md:px-4" @click.stop>
                <button class="absolute top-0 right-0 p-2 md:mt-5 md:mr-6" @click="$emit('close')">
                    <img src="@/static/images/icons/cross-black.svg" alt="Close">
                </button>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Modal",

        props: {
            isOpened: {
                type: Boolean,
                required: true,
            }
        }
    }
</script>

<style lang="scss">
    .modal {
        background-color: rgba(0, 0, 0, 0.7);

        &__container {
            max-width: 60rem;
        }
    }

    .modal-fade-enter-active, .modal-fade-leave-active {
        transition: opacity 150ms ease-in-out;
    }
    .modal-fade-enter, .modal-fade-leave-to {
        opacity: 0;
    }
</style>
