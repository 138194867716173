<template>
    <label class="variant-select">
        <span class="variant-select__label">
            {{ label }}
        </span>
        <select v-model="value" class="variant-select__select" @change="updateValue">
            <option v-for="(item, key) in counts" :key="key" :value="item">
                {{ item }}
            </option>
        </select>
    </label>
</template>

<script>
    export default {
        name: "VariantCountSelect",

        props: {
            counts: {
                type: Array,
                required: true,
            },
            label: {
                type: String,
                default: '',
            },
            value: {
                type: Number,
                default: 0,
            }
        },

        methods: {
            updateValue(event) {
                this.$emit('input', parseInt(event.target.value));
            }
        }
    }
</script>
