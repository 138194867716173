<template>
    <section class="hero-home relative overflow-hidden flex items-center h-screen">
        <div class="absolute inset-0">
            <slot name="background"></slot>
        </div>

        <div class="hero-home__main flex flex-col items-start md:flex-row relative w-full mx-auto px-container">
            <div class="hidden lg:block mx-6 mt-5">
                <img
                    ref="primaryLogo"
                    src="@/static/images/brand/logo-white.svg"
                    class="invisible"
                    width="260"
                    height="60"
                    alt="Logo Koralp"
                >
            </div>

            <div ref="seconqaryLogoContainer" class="invisible relative px-6 py-5">
                <span ref="filetLeft" class="absolute top-0 left-0 w-px h-screen bg-white opacity-60"></span>
                <span ref="filetTop" class="absolute top-0 left-0 h-px w-screen bg-white opacity-60"></span>
                <span ref="filetRight" class="absolute bottom-0 right-0 w-px h-screen bg-white opacity-60"></span>
                <span ref="filetBottom" class="absolute bottom-0 right-0 w-screen h-px bg-white opacity-60"></span>

                <img
                    ref="secondaryLogo"
                    src="@/static/images/brand/logo-space-fitters.svg"
                    width="145"
                    height="62"
                    class="invisible"
                    alt="Space Flitters"
                >
            </div>

            <div class="px-6 pt-7">
                <h1 ref="signature" class="h5 text-white invisible">
                    {{ signature }}
                </h1>
                <div class="flex flex-col md:flex-row items-start pt-2 md:pt-10">
                    <div ref="button1" class="invisible">
                        <slot name="button1"></slot>
                    </div>
                    <div ref="button2" class="invisible">
                        <slot name="button2"></slot>
                    </div>
                </div>
            </div>
        </div>

        <div class="absolute bottom-0 w-full flex justify-center mb-7 md:mb-8">
            <a href="#content" class="scroll-down-arrow p-2">
                <img class="scroll-down-arrow__icon" src="@/static/images/icons/chevron-down-white.svg" alt="Scroll down">
            </a>
        </div>
    </section>
</template>

<script>
    import {gsap} from "gsap";
    import {CSSPlugin} from "gsap/CSSPlugin";
    import {Power4, Power2} from "gsap/all";

    export default {
        name: "HeroHome",

        props: {
            signature: {
                type: String,
                default: '',
            },
        },

        mounted() {
            gsap.registerPlugin(CSSPlugin);

            let timeline = gsap.timeline();

            timeline.set(this.$refs.seconqaryLogoContainer, {
                autoAlpha: 1,
            });

            timeline.from(this.$refs.filetLeft, {
                y: 800,
                duration: 4,
                ease: Power2.easeInOut,
            }, 0);

            timeline.from(this.$refs.filetTop, {
                x: 1300,
                duration: 4,
                ease: Power2.easeInOut,
            }, 0);

            timeline.from(this.$refs.filetRight, {
                y: -800,
                duration: 4,
                ease: Power2.easeInOut,
            }, 0);

            timeline.from(this.$refs.filetBottom, {
                x: -960,
                duration: 4,
                ease: Power2.easeInOut,
            }, 0);

            timeline.to([this.$refs.primaryLogo, this.$refs.secondaryLogo, this.$refs.signature], {
                autoAlpha: 1,
                duration: 2,
            }, 3);

            timeline.to(this.$refs.primaryLogo, {
                autoAlpha: 0,
                y: -50,
                ease: Power4.easeInOut,
                duration: 1,
            }, 4.5);

            timeline.to([this.$refs.secondaryLogo, this.$refs.signature], {
                autoAlpha: 0.6,
                ease: Power4.easeInOut,
                duration: 1,
            }, 4.5);

            timeline.fromTo(this.$refs.button1, {
                autoAlpha: 0,
                y: -30,
            }, {
                autoAlpha: 1,
                y: 0,
                ease: Power4.easeInOut,
                duration: 1.25,
            }, 5);

            timeline.fromTo(this.$refs.button2, {
                autoAlpha: 0,
                y: -30,
            }, {
                autoAlpha: 1,
                y: 0,
                ease: Power4.easeInOut,
                duration: 1.25,
            }, 5.2);
        },
    }
</script>

<style lang="scss">
    .hero-home {
        &__main {
            max-width: 87.5rem;
        }

        &__video-background {
            @apply absolute w-full h-full object-cover object-center;

            @screen md {
                // IE and Edge bg cover fix
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }
</style>
