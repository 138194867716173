<template>
    <div class="carrousel-full-width overflow-hidden md:my-16">
        <div class="grid-container text-center mb-1 md:mb-5">
            <h2 class="overline">
                {{ heading }}
            </h2>
        </div>

        <div class="container mx-auto">
            <div class="flex justify-center mb-1 md:hidden">
                <dots
                    :current-step="currentSlideKey"
                    :length="slides.length"
                    @updateSlide="updateSlide"
                ></dots>
            </div>

            <carousel
                v-model="currentSlideKey"
                :per-page="1"
                :center-mode="true"
                :navigation-enabled="true"
                :pagination-enabled="false"
            >
                <slide v-for="(slide, key) in slides" :key="key" :class="{'slide-active': key === currentSlideKey}">
                    <div class="md:px-6">
                        <img :src="slide.photo.url" :alt="slide.photo.title" class="w-full h-auto">
                    </div>
                </slide>
            </carousel>

            <div class="md:px-6 mt-2 flex items-center">
                <template v-if="length > 1">
                    <pagination
                        :current-step="currentSlideKey + 1"
                        :length="slides.length"
                        class="hidden md:flex mr-4"
                    ></pagination>
                    <timer
                        :key="currentSlideKey"
                        :duration="5"
                        class="hidden md:block mr-4"
                        @timelineFilled="goToNextSlide"
                    ></timer>
                </template>

                <p class="caption px-container md:px-0">
                    {{ slides[currentSlideKey].legend }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import Dots from "./Dots";
    import Pagination from "./Pagination";
    import Timer from "./Timer";
    import {Carousel, Slide} from "vue-carousel";

    export default {
        name: "CarrouselFullWidth",

        components: {
            Carousel,
            Slide,
            Pagination,
            Timer,
            Dots,
        },

        props: {
            heading: {
                type: String,
                default: '',
            },

            slides: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                currentSlideKey: 0,
            }
        },

        methods: {
            goToNextSlide() {
                if(this.currentSlideKey === this.slides.length - 1) {
                    this.currentSlideKey = 0;
                    return;
                }

                this.currentSlideKey++;
            },

            updateSlide(key) {
                this.currentSlideKey = key;
            },
        }
    }
</script>

<style lang="scss">
    .carrousel-full-width {
        .VueCarousel-wrapper {
            overflow: visible;
        }

        .VueCarousel-navigation-button {
            @apply hidden;

            @screen md {
                @apply block w-3 h-3 bg-cover;
                margin: 0 !important;
                font-size: 0;
                transform: translateY(-50%) !important;
            }
        }

        .VueCarousel-navigation-prev {
            background-image: url('~@/static/images/icons/arrow-left.svg');
        }

        .VueCarousel-navigation-next {
            background-image: url('~@/static/images/icons/arrow-right.svg');
        }

        .VueCarousel-slide {
            @apply opacity-50;
            transition: opacity 200ms ease-in-out;

            &.slide-active {
                @apply opacity-100;
            }
        }
    }
</style>
