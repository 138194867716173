<template>
    <div class="carrousel-product-photos bg-gray-100">
        <div class="flex justify-center items-center">
            <img :src="photos[currentPhotoId].url" :alt="photos[currentPhotoId].title">
        </div>
        <div v-if="photos.length > 1" class="flex justify-center md:justify-between items-center h-7 md:px-2 lg:px-4 border-t border-black">
            <div class="hidden md:flex flex-shrink-0">
                <button class="mr-2" @click="updateCurrentPhoto(currentPhotoId - 1)">
                    <img src="@/static/images/icons/arrow-left.svg" alt="Previous">
                </button>
                <button @click="updateCurrentPhoto(currentPhotoId + 1)">
                    <img src="@/static/images/icons/arrow-right.svg" alt="Next">
                </button>
            </div>

            <div class="flex overflow-x-auto">
                <button
                    v-for="(image, key) in photos"
                    :key="key"
                    :class="{'border-black': key === currentPhotoId, 'border-transparent': key !== currentPhotoId}"
                    class="flex-shrink-0 border-4 focus:outline-none"
                    @click="updateCurrentPhoto(key)"
                >
                    <img class="w-5 h-5" :src="image.url" :alt="image.title">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CarrouselProductPhotos",

        props: {
            photos: {
                type: Array,
                required: true,
            }
        },

        data() {
            return {
                currentPhotoId: 0,
            }
        },

        methods: {
            updateCurrentPhoto(i) {
                if (i < 0) {
                    this.currentPhotoId = this.photos.length - 1;
                    return;
                }

                if (i === this.photos.length) {
                    this.currentPhotoId = 0;
                    return;
                }

                this.currentPhotoId = i;
            }
        }
    }
</script>

<style scoped>

</style>
