<template>
    <intersect :threshold="[0.3]" @enter="displayItem">
        <div class="production-overview-container">
            <carrousel-transition>
                <production-overview
                    v-if="isDisplayed"
                    :production="production"
                    :text-position="textPosition"
                />
            </carrousel-transition>
        </div>
    </intersect>
</template>

<script>
    import Intersect           from 'vue-intersect';
    import ProductionOverview  from './ProductionOverview';
    import CarrouselTransition from '../transitions/CarrouselTransition';

    export default {
        name: 'ProductionListOverview',

        components: {
            Intersect,
            ProductionOverview,
            CarrouselTransition,
        },

        props: {
            production:   {
                type:     Object,
                required: true,
            },
            textPosition: {
                type:    String,
                default: 'left',
            },
        },

        data () {
            return {
                isDisplayed: false,
            };
        },

        methods: {
            displayItem () {
                if (!this.isDisplayed) {
                    this.isDisplayed = true;
                }
            },
        },
    };
</script>
