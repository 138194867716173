<template>
    <div class="modal-opener">
        <button @click.prevent.stop="isModalOpened = true">
            <slot name="modal-button-content"></slot>
        </button>

        <modal :is-opened="isModalOpened" @close="isModalOpened = false">
            <slot name="modal-content"></slot>
        </modal>
    </div>
</template>

<script>
    import Modal from "./Modal";
    import {EventBus} from "../services/event-bus";

    export default {
        name: "ModalOpener",

        components: {
            Modal,
        },

        data() {
            return {
                isModalOpened: false,
            }
        },

        mounted() {
            EventBus.$on('open-modal', () => {
                this.isModalOpened = true;
            });
        }
    }
</script>

<style scoped>

</style>
