<template>
    <article class="h-full bg-cover bg-center bg-no-repeat" :style="`background-image: url('${card.backgroundImage.url}')`">
        <div class="flex flex-col justify-center items-start h-full p-2 md:p-3 text-white">
            <h3 class="overline">
                {{ card.heading }}
            </h3>
            <div class="lead mt-1 md:mt-2 w-full" v-html="card.text"></div>

            <a :href="card.cta.url" :target="card.cta.target" class="btn btn--l btn--negative mt-5 md:mt-6">
                {{ card.cta.label }}
            </a>
        </div>
    </article>
</template>

<script>
    export default {
        name: "ProductCtaCard",

        props: {
            card: {
                type: Object,
                required: true,
            }
        }
    }
</script>
