<template>
    <div class="pagination relative flex items-center justify-center w-3 h-4 caption">
        <span class="absolute top-0 left-0">{{ currentStep }}</span>
        /
        <span class="span absolute bottom-0 right-0">{{ length }}</span>
    </div>
</template>

<script>
    export default {
        name: "Pagination",

        props: {
            currentStep: {
                type: Number,
                default: 0,
            },
            length: {
                type: Number,
                default: 0,
            },
        }
    }
</script>

<style scoped>

</style>
