<template>
    <div
        :class="{'md:flex-row-reverse': textPosition === 'right', 'md:flex-row': textPosition === 'left'}"
        class="production-overview h-full flex flex-col md:items-center"
    >
        <div class="relative w-full md:w-1/2">
            <div class="carrousel-pagination-container">
                <slot></slot>
            </div>

            <div
                class="carrousel-text-container relative border border-black pt-10 md:pt-16 pb-5 md:pb-7 pr-10 md:pr-12"
            >
                <img
                    :alt="production.logo.title"
                    :src="production.logo.url"
                    class="absolute top-0 right-0 mt-2 mr-2 md:mt-4 md:mr-4"
                >
                <div class="flex items-start">
                    <img :alt="production.pictogram.value" :src="pictogramUrl" class="mr-4">
                    <a :href="production.cta.value" :target="production.cta.target" class="h2 no-underline">
                        <span class="block font-light">
                            {{ production.heading }}
                        </span>
                        {{ production.location }}
                    </a>
                </div>
            </div>
            <div
                :class="{'md:text-left carrousel-button--left': textPosition === 'right', 'md:text-right carrousel-button--right': textPosition === 'left'}"
                class="carrousel-button absolute top-full z-1 right-0 md:right-auto md:top-auto md:relative overflow-hidden text-center"
            >
                <a :href="production.cta.value" :target="production.cta.target" class="btn btn--positive btn--l">{{
                    production.cta.customText }}</a>
            </div>
        </div>
        <div class="w-full h-full md:w-1/2">
            <a
                :class="{'carrousel-image-container--left': textPosition === 'right', 'carrousel-image-container--right': textPosition === 'left'}"
                :href="production.cta.value"
                :target="production.cta.target"
                class="production-overview__image-container carrousel-image-container relative overflow-hidden block h-full bg-gray-300"
            >
                <img
                    :alt="production.image.title"
                    :src="production.image.url"
                    class="production-overview__image md:absolute w-full md:h-full md:object-cover md:object-center"
                >
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductionOverview',

        props: {
            production:   {
                type:     Object,
                required: true,
            },
            textPosition: {
                type:    String,
                default: 'left',
            },
        },

        computed: {
            pictogramUrl () {
                return require(`@/static/images/solutions/${this.production.pictogram.value}-sm.svg`);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .production-overview {
        &__image {
            transition: transform 0.75s ease-in-out;

            &:hover {
                transform: scale(1.08);
            }
        }
    }
</style>
