<template>
    <transition name="carrousel-transition" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "CarrouselTransition"
    }
</script>

<style lang="scss">
    .carrousel-transition-enter-active, .carrousel-transition-leave-active {
        transition: transform .3s ease-in-out;

        .carrousel-image-container {
            &::after {
                content: '';
                @apply absolute top-0 bottom-0 bg-white;
                width: 0;
                transition: width .3s ease-in-out;
            }

            &--left {
                &::after {
                    @apply left-0;
                }
            }

            &--right {
                &::after {
                    @apply right-0;
                }
            }
        }

        .carrousel-text-container {
            transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
        }

        .carrousel-pagination-container {
            transition: opacity 0.15s ease-in-out;
        }

        .carrousel-button {
            &::after {
                content: '';
                @apply absolute top-0 bottom-0 bg-white;
                width: 0;
                transition: width .3s ease-in-out;
            }

            &--left {
                &::after {
                    @apply right-0;
                }
            }

            &--right {
                &::after {
                    @apply left-0;
                }
            }
        }
    }

    .carrousel-transition-enter, .carrousel-transition-leave-to {
        transform: scale(0.94);

        .carrousel-image-container {
            &::after {
                width: 100%;
            }
        }

        .carrousel-text-container {
            transform: scale(0.8);
            opacity: 0;
        }

        .carrousel-pagination-container {
            opacity: 0;
        }

        .carrousel-button {
            &::after {
                width: 100%;
            }
        }
    }
</style>
