<template>
    <div class="dots inline-flex">
        <button
            v-for="(i, key) in length"
            :key="key"
            class="p-1"
            @click="$emit('updateSlide', key)"
        >
            <span :class="{'bg-black': key === currentStep, 'bg-gray-500': key !== currentStep}" class="dots__dot block rounded-full">
                {{ key }}
            </span>
        </button>
    </div>
</template>

<script>
    export default {
        name: "Dots",

        props: {
            currentStep: {
                type: Number,
                default: 0,
            },
            length: {
                type: Number,
                default: 0,
            },
        }
    }
</script>

<style scoped lang="scss">
    .dots {
        &__dot {
            width: 0.625rem;
            height: 0.625rem;
            font-size: 0;
        }
    }
</style>
