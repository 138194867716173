<template>
    <div class="accordion">
        <button class="flex items-center justify-between w-full border-0 bg-transparent focus:outline-none" @click="isOpened = !isOpened">
            <slot name="button-text"></slot>

            <img v-if="!isOpened" src="@/static/images/icons/add.svg" alt="Open">
            <img v-if="isOpened" src="@/static/images/icons/remove.svg" alt="Close">
        </button>
        <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
        >
            <div v-if="isOpened">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Accordion",

        data() {
            return {
                isOpened: false,
            }
        },

        methods: {
            enter(element) {
                element.style.height = 'auto';

                const height = getComputedStyle(element).height;
                element.style.height = 0;

                // Force repaint
                getComputedStyle(element).height;

                requestAnimationFrame(() => {
                    element.style.height = height;
                });
            },

            afterEnter(element) {
                element.style.height = 'auto';
            },

            leave(element) {
                element.style.height = getComputedStyle(element).height;

                // Force repaint
                getComputedStyle(element).height;

                requestAnimationFrame(() => {
                    element.style.height = 0;
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .accordion {
        .expand-enter-active,
        .expand-leave-active {
            transition: height 300ms;
            overflow: hidden;
        }

        .expand-enter,
        .expand-leave-to {
            height: 0;
        }
    }
</style>
