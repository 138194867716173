<template>
    <div class="featured-options-and-accessories overflow-hidden mt-12 mb-9 md:mt-16 md:mb-10">
        <div class="grid-container">
            <h2 class="overline text-center mb-1 md:mb-7">
                {{ heading }}
            </h2>
        </div>
        <div class="md:px-container-lg">
            <div class="flex justify-center mb-1 md:hidden">
                <dots
                    :current-step="currentSlideKey"
                    :length="products.length"
                    @updateSlide="updateSlide"
                ></dots>
            </div>

            <carousel
                v-model="currentSlideKey"
                :per-page-custom="[[320, 1], [768, 2], [992, 3], [1200, 4]]"
                :pagination-enabled="false"
                :navigation-enabled="true"
            >
                <slide v-for="(product, key) in products" :key="key">
                    <article class="h-full border border-black pt-1 px-3 pb-4 mx-2 md:pt-2 md:px-3">
                        <img :src="product.photo.url" :alt="product.photo.title">

                        <p class="h4">
                            <span class="font-light">
                                {{ product.reference }}
                            </span>
                            <br>
                            {{ product.name }}
                        </p>
                    </article>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
    import {Carousel, Slide} from "vue-carousel";
    import Dots from "./Dots";

    export default {
        name: "FeaturedOptionsAndAccessories",

        components: {
            Carousel,
            Slide,
            Dots,
        },

        props: {
            heading: {
                type: String,
                default: null,
            },

            products: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                currentSlideKey: 0,
            }
        },

        methods: {
            updateSlide(key) {
                this.currentSlideKey = key;
            }
        }
    }
</script>

<style lang="scss">
    .VueCarousel-navigation-button {
        @apply hidden;

        @screen md {
            @apply block w-3 h-3 bg-cover;
            top: 0 !important;
            font-size: 0;
        }
    }

    .VueCarousel-navigation-prev {
        transform: translate(60%, -150%) !important;
        background-image: url('~@/static/images/icons/arrow-left.svg');
    }

    .VueCarousel-navigation-next {
        transform: translate(-60%, -150%) !important;
        background-image: url('~@/static/images/icons/arrow-right.svg');
    }

    .VueCarousel-slide {
        @apply py-px;
    }
</style>
