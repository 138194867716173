<template>
    <transition name="scroll-up-fade">
        <div v-if="isVisible" class="scroll-up-menu grid-container fixed z-10 flex justify-center md:justify-between items-center w-full h-8 bg-white border-t md:border-t-0 md:border-b border-black">
            <div class="hidden md:flex items-center">
                <a :href="backNavigationUrl" class="mr-4">
                    <img src="@/static/images/icons/arrow-left-bold.svg" alt="Back">
                </a>
                <p class="body-4 hidden lg:block">
                    {{ heading }}
                </p>
            </div>

            <div class="flex items-center">
                <ul class="list-none hidden md:flex items-center mr-5">
                    <li v-for="(anchor, key) in anchors" :key="key">
                        <a :href="anchor.value" class="link-s mx-3">
                            {{ anchor.customText }}
                        </a>
                    </li>
                </ul>
                <button v-if="rightCtaModalOpenerTitle" class="btn btn--positive btn--s" @click="triggerOpenModalEvent">
                    {{ rightCtaModalOpenerTitle }}
                </button>
                <slot v-if="!rightCtaModalOpenerTitle"></slot>
            </div>
        </div>
    </transition>
</template>

<script>
    import {EventBus} from '../services/event-bus.js';

    export default {
        name: "ScrollUpMenu",

        props: {
            backNavigationUrl: {
                type: String,
                required: true,
            },
            heading: {
                type: String,
                required: true,
            },
            anchors: {
                type: Array,
                required: true,
            },
            rightCtaModalOpenerTitle: {
                type: String,
                default: null,
            }
        },

        data() {
            return {
                isVisible: false,
            }
        },

        mounted() {
            window.addEventListener('scroll', this.scrollhandler);
        },

        destroyed() {
            window.removeEventListener('scroll', this.scrollHandler);
        },

        methods: {
            scrollhandler() {
                if(window.pageYOffset > 125 && !this.isVisible) {
                    this.isVisible = true;
                    return;
                }

                if(window.pageYOffset < 125 && this.isVisible) {
                    this.isVisible = false;
                }
            },

            triggerOpenModalEvent() {
                EventBus.$emit('open-modal');
            }
        }
    }
</script>

<style scoped lang="scss">
    .scroll-up-menu {
        bottom: 0;

        @screen md {
            top: 4rem;
            bottom: auto;
        }
    }

    .scroll-up-fade-enter-active, .scroll-up-fade-leave-active {
        transition: opacity .2s, transform .2s ease-in-out;
    }
    .scroll-up-fade-enter, .scroll-up-fade-leave-to {
        opacity: 0;
        transform: translateY(65px);
    }
</style>
