<template>
    <form class="flex flex-col items-start md:flex-row md:flex-wrap" @change="submitForm">
        <div
            v-for="productType in enabledProductTypes"
            :key="productType.slug"
            class="flex items-center mb-1 md:mr-1 pl-2 pr-1 py-1 border border-black rounded-sm"
        >
            <input
                :id="productType.slug"
                v-model="productTypeModel"
                type="checkbox"
                name="product-types[]"
                :value="productType.slug"
                class="sr-only"
            >

            <img :src="productType.pictogram.url" :alt="productType.pictogram.alt" class="mr-1" />
            <p class="mr-1 font-medium leadin-tight text-xs text-black" v-text="productType.label" />

            <label :for="productType.slug" aria-label="Remove filter" class="px-1 cursor-pointer">
                <img src="@/static/images/icons/cross-black.svg" :alt="productType.pictogram.alt">
            </label>
        </div>

        <div
            v-for="productFeature in enabledProductFeatures"
            :key="productFeature.slug"
            class="flex items-center mb-1 md:mr-1 pl-2 pr-1 py-1 border border-black rounded-sm"
        >
            <input
                :id="productFeature.slug"
                v-model="productFeatureModel"
                type="checkbox"
                name="product-features[]"
                :value="productFeature.slug"
                class="sr-only"
            >

            <img :src="productFeature.pictogram.url" :alt="productFeature.pictogram.alt" class="mr-1" />
            <p class="mr-1 font-medium leadin-tight text-xs text-black" v-text="productFeature.label" />

            <label :for="productFeature.slug" aria-label="Remove filter" class="px-1 cursor-pointer">
                <img src="@/static/images/icons/cross-black.svg" :alt="productFeature.pictogram.alt">
            </label>
        </div>
    </form>
</template>

<script>
    export default {
        name: 'ProductListFilterSummary',

        props: {
            productTypes: { type: Array, required: true },
            productFeatures: { type: Array, required: true },
        },

        data() {
            return {
                productTypeModel: this.productTypes.map(item => item.slug),
                productFeatureModel: this.productFeatures.map(item => item.slug),
            };
        },

        computed: {
            enabledProductTypes() {
                return this.productTypes.filter(item => (this.productTypeModel.indexOf(item.slug)) !== -1);
            },

            enabledProductFeatures() {
                return this.productFeatures.filter(item => (this.productFeatureModel.indexOf(item.slug)) !== -1);
            }
        },

        methods: {
            submitForm(event) {
                this.$el.submit();
            }
        },
    }
</script>
