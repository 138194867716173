<template>
    <div class="customer-nav">
        <div class="mb-6 text-center">
            <h1 class="overline">
                {{ heading }}
            </h1>

            <a :href="logoutLink.url" class="btn-text btn-text--line btn-text--positive">
                {{ logoutLink.title }}
            </a>
        </div>

        <ul class="tabs-bar hidden list-none lg:flex">
            <li
                v-for="(link, key) in links"
                :key="key"
                :class="{'tabs-bar__item--active': isSelected(key)}"
                class="w-1/4 pb-3 border-b-2"
            >
                <a :href="link.url" class="tabs-bar__link body-1 flex justify-center">
                    {{ link.title }}
                </a>
            </li>
        </ul>

        <select
            v-model="selectedValue"
            class="lg:hidden select w-full"
            name="solutionsSelect"
            @change="selectChangeHandler"
        >
            <option
                v-for="(link, key) in links"
                :key="key"
                :value="link.url"
            >
                {{ link.title }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "CustomerNav",

        props: {
            heading: {
                type: String,
                default: '',
            },
            links: {
                type: Array,
                required: true,
            },
            currentTabId: {
                type: Number,
                required: true,
            },
            logoutLink: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                selectedValue: null,
            }
        },

        mounted() {
            for(let i in this.links) {
                if (parseInt(i) === this.currentTabId) {
                    this.selectedValue = this.links[i].url;
                }
            }
        },

        methods: {
            isSelected(key) {
                return key === this.currentTabId;
            },

            selectChangeHandler() {
                window.location.href = this.selectedValue;
            },
        }
    }
</script>
