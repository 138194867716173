<template>
    <div class="carrousel-our-production pt-10 md:pt-12 pb-12 md:pb-16">
        <div class="container mx-auto">
            <h2 class="overline text-center mb-2 md:mb-7">
                {{ heading }}
            </h2>

            <div class="production-overview-container">
                <carrousel-transition>
                    <production-overview
                        :key="currentProductionId"
                        :production="currentProduction"
                        :text-position="currentTextPosition"
                    >
                        <div class="flex items-center justify-center mb-4">
                            <button class="px-1" @click="previous">
                                <img alt="Previous" src="@/static/images/icons/arrow-left.svg">
                            </button>
                            <button class="px-1" @click="next">
                                <img alt="Next" src="@/static/images/icons/arrow-right.svg">
                            </button>

                            <pagination
                                :current-step="currentProductionId + 1"
                                :length="productions.length"
                                class="ml-3"
                            />

                            <timer :duration="3.5" class="ml-2 lg:ml-5 xl:ml-10" @timelineFilled="next"/>
                        </div>
                    </production-overview>
                </carrousel-transition>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination          from './Pagination';
    import CarrouselTransition from '../transitions/CarrouselTransition';
    import ProductionOverview  from './ProductionOverview';
    import Timer               from './Timer';

    export default {
        name: 'CarrouselOurProduction',

        components: {
            Pagination,
            CarrouselTransition,
            ProductionOverview,
            Timer,
        },

        props: {
            heading:     {
                type:    String,
                default: '',
            },
            productions: {
                type:    Array,
                default: null,
            },
        },

        data () {
            return {
                currentProductionId: 0,
            };
        },

        computed: {
            currentProduction () {
                return this.productions[ this.currentProductionId ];
            },

            currentTextPosition () {
                if (this.currentProductionId % 2 === 0) {
                    return 'left';
                }

                return 'right';
            },
        },

        methods: {
            next () {
                if (this.currentProductionId === this.productions.length - 1) {
                    this.currentProductionId = 0;
                    return;
                }

                this.currentProductionId++;
            },

            previous () {
                if (this.currentProductionId === 0) {
                    this.currentProductionId = this.productions.length - 1;
                    return;
                }

                this.currentProductionId--;
            },
        },
    };
</script>
