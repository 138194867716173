<template>
    <component :is="product.url ? 'a' : 'div'" :href="product.url" class="block w-full no-underline">
        <article class="product-card relative flex flex-col">
            <div class="bg-gray-100 pt-3 px-2 md:px-5">
                <div class="relative flex justify-center items-center">
                    <img
                        v-if="product.photos"
                        :src="product.photos[0].url"
                        :alt="product.photos[0].alt"
                        :width="product.photos[0].width"
                        :height="product.photos[0].height"
                        class="max-w-full max-h-full w-auto h-auto"
                    >

                    <div v-if="product.photos[1]" class="product-card__image-hover bg-gray-100 opacity-0 absolute inset-0 z-1 flex justify-center items-center">
                        <img
                            v-if="product.photos"
                            :src="product.photos[1].url"
                            :alt="product.photos[1].alt"
                            class="max-w-full max-h-full w-auto h-auto"
                        >
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 px-3">
                <div class="border-l border-black py-2 pl-2 h4">
                    <div class="font-light">
                        {{ product.reference }}
                    </div>
                </div>
            </div>

            <div class="flex flex-col items-start border-l border-black mx-3 py-2 pl-2">
                <div v-if="product.subHeading" class="product-card__sub-heading rounded-sm mb-1 px-1 pt-px mention bg-gray-800 text-white">
                    {{ product.subHeading }}
                </div>

                <h4>{{ product.title }}</h4>
            </div>

            <div v-if="product.variant" class="absolute z-1 top-0 flex flex-col items-end right-0 m-2 px-2 py-1 rounded-sm bg-white">
                <span class="caption">{{ priceStartingAtText }}</span>
                <span class="body-2 flex flex-col items-end">
                    {{ product.variant.salePrice }}
                    <template v-if="product.variant.crossedPrice">
                        <br>
                        <del>{{ product.variant.crossedPrice }}</del>
                    </template>
                </span>
            </div>

            <div v-if="product.label" class="absolute z-1 top-0 left-0 m-2 px-1 pt-px mention bg-white rounded-sm">
                {{ product.label }}
            </div>
        </article>
    </component>
</template>

<script>
    export default {
        name: "ProductCard",

        props: {
            product: {
                type: Object,
                required: true,
            },
            priceStartingAtText: {
                type: String,
                default: '',
            },
        }
    }
</script>

<style scoped lang="scss">
    .product-card {
        &__image-hover {
            transition: opacity 150ms ease-in-out;
        }

        &__sub-heading {
            margin-top: -1.75rem;
        }

        &:hover {
            color: inherit;

            .product-card__image-hover {
                @apply opacity-100;
            }
        }
    }
</style>
