<template>
    <div class="solutions-tab-bar">
        <ul class="tabs-bar hidden list-none lg:flex">
            <li :class="{'tabs-bar__item--active': currentSolutionSlug === ''}" class="tabs-bar__item w-1/4 pb-2 text-center border-b-2">
                <a :href="allSolutionsUrl" class="tabs-bar__link body-1">
                    {{ allSolutionsTitle }}
                </a>
            </li>

            <li
                v-for="(solution, key) in solutions"
                :key="key"
                :class="{'tabs-bar__item--active': isSelected(solution.slug)}"
                class="tabs-bar__item w-1/4 pb-2 border-b-2"
            >
                <a :href="getSolutionUrl(solution.slug)" class="tabs-bar__link body-1 flex justify-center">
                    <img :src="getPictogramUrl(solution.pictogram)" :alt="solution.pictogram" class="mr-3">
                    {{ solution.title }}
                </a>
            </li>
        </ul>

        <div class="relative lg:hidden">
            <div class="absolute left-0 flex items-center h-full ml-3">
                <img
                    v-if="currentPictogram !== null"
                    :src="getPictogramUrl(currentPictogram)"
                    :alt="currentPictogram"
                >
            </div>
            <select
                v-model="selectedValue"
                :class="{'solutions-tab-bar__select-with-picto': currentPictogram !== null}"
                class="select w-full"
                name="solutionsSelect"
                @change="selectChangeHandler"
            >
                <option :value="allSolutionsUrl">
                    {{ allSolutionsTitle }}
                </option>
                <option
                    v-for="(solution, key) in solutions"
                    :key="key"
                    :value="solution.slug"
                >
                    {{ solution.title }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SolutionsTabBar",

        props: {
            allSolutionsTitle: {
                type: String,
                required: true,
            },
            allSolutionsUrl: {
                type: String,
                required: true,
            },
            solutions: {
                type: Array,
                required: true,
            },
            currentSolutionSlug: {
                type: String,
                default: '',
            }
        },

        data() {
            return {
                selectedValue: null,
            }
        },

        computed: {
            currentPictogram() {
                let pictogram = null;

                this.solutions.forEach((element) => {
                    if(Object.values(element).indexOf(this.selectedValue) > -1) {
                        pictogram = element.pictogram;
                    }
                });

                return pictogram;
            }
        },

        mounted() {
            if(this.currentSolutionSlug !== '') {
                this.selectedValue = this.currentSolutionSlug;
                return;
            }

            this.selectedValue = this.allSolutionsUrl;
        },

        methods: {
            isSelected(slug) {
                return slug === this.currentSolutionSlug;
            },

            getPictogramUrl(pictogram) {
                return require(`@/static/images/solutions/${pictogram}-xs.svg`);
            },

            getSolutionUrl(slug) {
                return this.allSolutionsUrl + '/' + slug;
            },

            selectChangeHandler() {
                if(this.selectedValue === this.allSolutionsUrl) {
                    window.location.href = this.allSolutionsUrl;
                    return;
                }

                window.location.href = this.getSolutionUrl(this.selectedValue);
            },
        }
    }
</script>

<style scoped lang="scss">
    .solutions-tab-bar {
        &__select-with-picto {
            @apply pl-8;
        }
    }
</style>
